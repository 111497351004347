import { Injectable } from '@angular/core';
import { USER_CUSTOMER, USER_PARTNER, USER_ADMIN, USER_SALES } from '../authorization/user/user-group/user-group';
import { UserService } from '../authorization/user/user.service';
import { ArticleListService } from '../article-list/article-list.service';
import { filesMap } from '../price-calculator-es/price-calculator-es';
import { DatabaseQueryCondition } from '../article-list/database-query-condition';

@Injectable({
	providedIn: 'root'
})

/**
 * Defines product series strings for germany and spain
 */
export class ProductService {
	private static groups = [
		'band',
		'blattware',
		'rolle',
		'scheibe',
		'jumbo',
		'zubehoer'
	] as const;

	private static groupsEs = [
		'Banda Estrecha',
		'Banda Portatil',
		'Banda Ancha',
		'Anillos reforzados',
		'Discos',
		'Cepillos',
		'Rollos',
		'Rollos Estandar',
		'Hojas',
		'Accesorios',
	] as const;
	public isCustomer: boolean = false;
	public isPartner: boolean = false;
	public isSales: boolean = false;
	public isAdmin: boolean = false;

	constructor(private readonly userService: UserService, private readonly articleListService: ArticleListService) {
	}

	public async initUser(): Promise<void> {
		const response = await this.userService.getCurrentUser();
		this.isAdmin = response.userGroup.id === USER_ADMIN;
		this.isCustomer = response.userGroup.id === USER_CUSTOMER;
		this.isPartner = response.userGroup.id === USER_PARTNER;
		this.isSales = response.userGroup.id === USER_SALES;
	}


	/**
	 * Object literal to store all the different pf files on page load
	 */
	private files = {
		accessorios: null,
		bportatiles: null,
		cepillos: null,
		discos: null,
		hojas: null,
		producto: null,
		rolloStandard: null,
		coeficientes: null,
		anillos: null,
	};

	/**
	 * Initialize pf files on page load.
	 */
	private async initializeFiles(): Promise<void> {
		const fileNames = ['Accesorios', 'Bportatiles', 'Cepillos', 'Discos', 'Hojas', 'Producto', 'RolloStandard', 'Coeficientes', 'Anillos'];
		const promises = [];
		fileNames.forEach((fileName) => promises.push(this.articleListService.getPfFile(fileName, 'es')));
		[
			this.files.accessorios,
			this.files.bportatiles,
			this.files.cepillos,
			this.files.discos,
			this.files.hojas,
			this.files.producto,
			this.files.rolloStandard,
			this.files.coeficientes,
			this.files.anillos,
		] = await Promise.all(promises);
	}

	public async getGroups(): Promise<typeof ProductService.groups> {
		return ProductService.groups;
	}

	public async getGroupsEs(): Promise<('Banda Estrecha' | 'Banda Portatil' | 'Banda Ancha' | 'Anillos reforzados' | 'Discos' | 'Cepillos' | 'Rollos' | 'Rollos Estandar' | 'Hojas' | 'Accesorios')[]> {
		await this.initUser();
		if (this.isAdmin) {
			return ProductService.groupsEs as any;
		}
		return this.filterProductGroupsByCustomerGroup();
	}

	private async filterProductGroupsByCustomerGroup() {
		await this.initializeFiles();
		const query: any = {};

		if (this.isSales) {
			query['Verkauf '] = new DatabaseQueryCondition('==', 'SI');
		}
		if (this.isCustomer) {
			query['Kunde'] = new DatabaseQueryCondition('==', 'SI');
		}
		if (this.isPartner) {
			query['Partner'] = new DatabaseQueryCondition('==', 'SI');
		}

		return ProductService.groupsEs.filter((mainGroup) => {
			let currentFile = this.files[filesMap[mainGroup]];
			if (currentFile === undefined) {
				currentFile = this.files.producto;
			}
			const res = currentFile.where(query).execute();
			return res.length > 0;
		});
	}
}
